var site = site || {};
site.favorites = site.favorites || {};

(function($) {

    site.favorites.manipulateWishList = function(args) {
        var args = args || {};

        var params = {
            "_SUBMIT": "alter_collection",
            "action": "add"
        };

        var skuBaseId;
        if (args.skuData && args.skuData.SKU_BASE_ID) {
            skuBaseId = args.skuData.SKU_BASE_ID;
        } else if (args.skuBaseId) {
            skuBaseId = args.skuBaseId;
        }

        if ( skuBaseId ) { params.SKU_BASE_ID = skuBaseId; }

        params.action = args.action || params.action;

        if ( args.COLLECTION_ID ) {
            params.COLLECTION_ID = args.COLLECTION_ID;
        }

        var catBaseId = '';
        if (args.skuData && args.skuData.PARENT_CAT_ID) {
            var matchResult = args.skuData.PARENT_CAT_ID.match("[0-9]+");
            if (matchResult) {
                params.CAT_BASE_ID = matchResult[0];
            }
        }

        var successCallback = args.successCallback || function() {};
        var errorCallback = args.errorCallback || function() {};

        var id = generic.jsonrpc.fetch({
            method : 'rpc.form',
            params: [params],
            onSuccess:function(jsonRpcResponse) {
                console.log("modify favorites success");
                console.log(jsonRpcResponse.getData());
                var d = jsonRpcResponse.getData();
                var r = d.ac_results[0].result;

                successCallback( r );
            },
            onFailure: function(jsonRpcResponse) {
                console.log("modify favorites failure");
                console.log( jsonRpcResponse.getError() );

                errorCallback( jsonRpcResponse.getError() );
            }
        });
    };

})(jQuery);
